import React from 'react'
import styled from 'styled-components'
import EventLight from '../components/EventLight'
import Event1 from '../components/Event-1'
import Event2 from '../components/Event-2'

const Event = () => {
  return (
   <EventContainer> 
     <EventContent>
          <TopLIne>  Evènements  </TopLIne>
          <FB>
              <H5>Artiste aux techniques multiples, je partage mon art dans les espaces d’art contemporains et lieux privés en France et Belgique.
                 </H5>
              <H5> -Christine G. , Toulouse FR
                 </H5>
          </FB>
     </EventContent>
     <Event1 />
     <Event2 />
     <EventLight />
   </EventContainer>
  )
}

export default Event

 {/* Un overlay sombre sur la video avec :before pour mettre en avant le texte  */}
const EventContainer = styled.div `
  justify-content: center ;
  align-items: center;
  color: rgba(69, 73, 69, 0.636);
  }
`
const EventContent = styled.div`
  min-height: 0vh;
  padding: 0rem calc((100vw - 1300px) / 2);
`
const FB = styled.div`
    font-weight: bold;
    text-align: center;
    font-family: "BrandonGrotesque_black";
      h5 {
        padding: 20px;
      }
      a{
        font-size: 40px;
        color: white;
            @media screen and (max-width: 768px) {
              font-size: 45px;
            }
      }
    @media screen and (max-width: 768px) {
      h5 {
        margin: 0rem;
      }
    }

`
const H5 = styled.div`
margin: 2rem 15rem;
font-size: 18px;
color : #404640;
    @media screen and (max-width: 768px) {
      font-size: 15px;
      margin: 3.8rem 2rem;
      padding: inherit; }
`
const TopLIne = styled.h1`
text-align: center;
padding-top: 0px;
  font-family: BetterGrade;  
  font-size: 10rem; 
  color : #404640;
  font-weight: 400;
  margin-top: 3.5rem;
    @media screen and (max-width: 768px) {
      font-size: 5rem; 
`
